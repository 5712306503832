@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Sequel-Sans-SemiBold';
  src:url('/src/Components/Font/Sequel\ Sans\ Semi\ Bold\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
 font-family: "Sequel-Sans-BookBody";
  src: url('/src/Components/Font/Sequel\ Sans\ Book\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Sequel-Sans-BoldBody";
  src: url('/src/Components/Font/Sequel\ Sans\ Bold\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Sequel-Sans-MediumBody";
  src: url('/src/Components/Font/Sequel\ Sans\ Medium\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Sequel-Sans-RomanBody";
  src: url('/src/Components/Font/Sequel\ Sans\ Roman\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Sequel-Sans-BookOblBody";
  src: url('/src/Components/Font/Sequel\ Sans\ Book\ Obl\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Sequel-Sans-Light-Body";
  src: url('/src/Components/Font/Sequel\ Sans\ Light\ Body.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.compare-company .scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

/* For IE, Edge and Firefox */
.compare-company .scrollbar-hide {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #DADADA; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DADADA; 
}

.no-scrollbar::-webkit-scrollbar {
  /* display: none; */
  width:5px;
}
.scrollbar-none::-webkit-scrollbar {
  height:0px;
  width:0px;
}

input[type=checkbox] , input[type=radio] {
  accent-color: #BC8B20 !important;
}
.read-more-less--more, .read-more-less--less {
  color: #BC8B20;
}

/* .truncate {
  width: 10rem;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.stickyCol{
  position: sticky;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    left: 0px;
}

.listPage td, .listPage th{
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
  cursor: default;
}



.comapre_company {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 22px; */
  margin: 0;
}

.tableTd{
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* line-height: 25px; */
  /* margin: 0; */
  /* cursor: default; */
}

.valutionMore{
  max-width: 311px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 25px; */
  margin: 0;
 
  /* cursor: default; */
}

@media only screen and (max-width: 768px){
  .valutionMore{
    max-width: 311px;
    white-space: break-spaces !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
   
  }
  }

.table{
  width: 100%;
}

.investorProfile tr td{
  width: 25% !important;
}

.staticData{
  opacity: 0.7;
}
.blur-btn{
  filter:invert(70%) sepia(5%) saturate(21%) hue-rotate(354deg) brightness(86%) contrast(91%);

}
.share-btn-color{
  filter: invert(46%) sepia(18%) saturate(1744%) hue-rotate(359deg) brightness(97%) contrast(94%)
}

/* .custom-style > span > mark{
  background: #98630a42;
  border: 1px solid #98630a;
  color: #666666;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size:13px;
  font-family: 'Sequel-Sans-BookBody';
} */

.custom-style > span > mark, .mark {
  background: transparent;
  /* border: 1px solid #98630a; */
  color: #98641d;
  /* border-radius: 4px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 13px; */
  font-family: 'Sequel-Sans-BookBody';
}

.more-match {
  /* padding: 7px;
  background: #98641d;
  color: #FFF;
  width: 12%;
  border-radius: 5px;
  text-align: center;
  font-size: 13px; */

  padding: 7px;
  /* background: #98641d; */
  color: #666;
  font-weight: bold;
  width: 12%;
  border-radius: 5px;
  /* text-align: center; */
  text-align: left;
  font-size: 13px;


}

.custom-mark-tag {
  background: transparent;
  text-decoration: underline;
  /* border: 1px solid #98630a; */
  color: #666666;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  float: right;
  font-size:13px;
  font-family: 'Sequel-Sans-BookBody';
}
.custom-icon{
  backGround: #98630a;
}
.more-match-disabled{
  background:#98641d4f; 
}


/* addnote */

.Toastify__close-button {
  margin-top: 17px !important;
}


.selection-widget {
  min-height: 200px;
  overflow-y: auto;
}

.bg-wrapper:nth-child(even) 
{ background-color: #FAF5EA; }

.css-bg1rzq-control{
  color:#BC8B20 !important;
  min-height:0px !important;
}
.css-bgvzuu-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: none !important; 
  margin-top: 0px;
  width: 0px;
  box-sizing: none;
}
.css-16pqwjk-indicatorContainer {
  color: #BC8B20;  
  display: none !important;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}
.css-1thkkgx-indicatorContainer {
  color:#BC8B20;  
  display: none !important;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}
.css-1szy77t-control {
 
  border-color: none;  
  box-shadow: none;  
}

.css-12jo7m5 {
  border-radius: 0px !important;
  border-right: 1.5px solid #BC8B20 !important; 
  margin-top: 3px; 
  margin-bottom: 3px; 
}

.css-es53b3-multiValue {
  background-color: none !important;
  border:1px solid #BC8B20;
  border-radius: 4px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}
.css-bg1rzq-control {
  
  background-color:none;
  border-color:none;
  border:none !important;
  border-radius: 4px;
  border-style: none;
  display: none;
  box-sizing: border-box;
}

.css-es53b3-multiValue {
  background-color:white !important;
  color:#BC8B20 !important;
}

.css-1szy77t-control {
  
  background-color: white;  
}
.css-1g6gooi {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  visibility: visible;
  color:none;
  box-sizing: border-box;
}
.css-1szy77t-control {
 
  background-color: white !important;
  border-color:#BC8B20;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #BC8B20 !important; 
  outline:none !important;  
}
.css-12jo7m5 {  
  color: #BC8B20 !important;
  padding: 0px !important;  
  padding-right: 3px !important;
  padding-left: 6px !important;
} 

.css-1alnv5e:hover {
  background-color: #FFFFFF !important;
  color: #DE350B;
}

.css-151xaom-placeholder {
  color: hsl(0,0%,50%);
  font-size: 13px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
.css-1hwfws3{
  padding: 0px 8px !important;
}


  @media only screen and (min-width: 1500px) and (max-width: 1600px){
    .fixedFooter{
      height:70% !important;
    }

  }
  @media only screen and (min-width: 1600px) and (max-width: 1700px){
    .fixedFooter{
      height:72% !important;
    }

  }


/* .card-animate, td.tableTd, .data-td-animate, .group1 td {
  transition: all 0.4s;
}

.card-animate:hover, td.tableTd:hover, .data-td-animate:hover, .group1 td:hover {
   transform: translateY(calc(-1.5rem / 5));
   box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}


.tag-animate, .menu-item, .raise-request-btn  {
  transition: all 0.4s;
}

.tag-animate:hover, .menu-item:hover, .raise-request-btn:hover {
   transform: translateY(calc(-1.5rem / 5));
} */

   /* box-shadow: 0 5px 10px rgb(30 32 37 / 12%); */




.avatar-group {
  padding-top:12px;
	padding-left:12px;
	display: -webkit-box;
	display: -ms-flexbox;
	/* display:flex; */
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.avatar-group .avatar-item {
	margin-left: -12px;
	border: 2px solid var(--vz-card-bg-custom);
	border-radius: 50%;
	-webkit-transition: all .2s;
	transition: all .2s
}

.avatar-group .avatar-item:hover {
	position: relative;
	/* -webkit-transform: translateY(-2px); */
	/* transform: translateY(-2px); */
	z-index: 1;
  transform: translateY(calc(-1.5rem / 5));
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}

.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.rounded-circle {
  border-radius: 50%!important;
}

.d-inline-block {
  display: inline-block!important;
}


.co-date {
  position: absolute;
  width: 117px;
  bottom: -16px;
  font-size: 8px;
  /* left: -45px; */
  text-align: justify;
} 


.ant-popover-inner{
  max-width: 40rem;
}
.ant-popover{
 color: #333333;
 max-width: 30rem;
  }

/* #co-inverstor table tr {
  height: 200px;
} */

.ant-select-selection-item-remove {
  margin-top: -2px !important;
}
/* *, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: white;
} */

#custom-border-remove >  *:last-child  > button{
  border:none !important;
}
.customSelectOption:hover{
background-color: #BC8B20 !important;
}


.shadow-card {
  --tw-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(48 86 211 / var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 255 / var(--tw-bg-opacity));
}

.rounded {
  border-radius: 0.25rem;
}

.items-center {
  align-items: center;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #A5711A!important;
  color: #fff;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #A5711A !important;
  color: #fff !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.01rem !important;
}
.bm-cross-button{
  z-index: 10;
}
.custom-z-index{
  z-index: 9;
}
@media only screen and (max-width: 768px){
.scrollbar-remove::-webkit-scrollbar {
  height:0px;
  width:0px;
}
}

.thin-scrollbar::-webkit-scrollbar {
  height:7px;
  width:10px;
}
.place::placeholder{  
  font-size: 15px;
}

.reports-select .ant-select-selection-item{
font-size: 16px !important;
font-weight: 400 !important;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.reports-select .ant-select-arrow{
  color:black !important;
}

.no-scrollbar1::-webkit-scrollbar {
  /* display: none; */
  width:5px;
  height:5px;
}
.custom-box-shadow{
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.elipsesTooltip{
  display: -webkit-box;
    max-height: 300px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-switch {
  background-color: black; /* Default background color for the Switch */
}

.ant-switch-checked {
  background-color: #f5222d; /* Background color when the Switch is checked */
}

.grid-item-container {
  break-inside: avoid;
  page-break-inside: avoid;
}